import React, { Component } from 'react';
import './screen.scss';
import ErrorAlert from '../../components/alertmessages';
//import LoadingAnimation from '../../components/loadingAnimation';
//import LoadingImg from './loading.svg';
//import HomeImg from './home-logo.png';
//import lucidloader from './lucid-loader.svg';
//import {Cookies} from 'react-cookie';
import { isMobile } from 'react-device-detect';
import Config from '../../data/SiteConfig';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
//import * as QueryString from "query-string";
import uuid from 'react-uuid';
//import { style } from 'glamor';

let orgId = '';
let orgName = '';
let propId = '';
let propName = '';
let outCode = '';
let outName = '';
let tblNo = '';
let sType = '';
let statsid = '';
let urlRemarks = '';
let mobileApp = '';
let appContactNo = '';
let appCountryCode = '';
let token = '';

let mobileNo = '';
// let loggedInOrg = '';
// let loggedOrgName = '';
// let loggedInPropertyId = '';
// let loggedPropertyName = '';
// let loggedInOutletcode = '';
// let loggedOutletName = '';
// let loggedInServiceType = '';
// let loggedInTableno = '';
// let loggedStatsid = '';
// let loggedurlRemarks = '';
let outletDetails = '';
//let inputParam = '';
let exloggedout = false;
let urlPattern = '';
class SplashScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            orgId: 0,
            propertyId: "",
            outletCode: "",
            tableNo: "",
            serviceType: "",
            pageContent: "",
            pagePreview: "",
            pagebgColor: {
                background: "#fff",
            },
            poweredByStyles: {
                background: "#fff",
                color: "#000",
                fontSize: "10px"
            },
            poweredByLucidStyles: {
                color: "#fd0000",
            },
            logoheight: {
               height:"115px"
            },

        }
    }

    // componentWillMount() {
    //     sessionStorage.clear();
    //     // alert();
    // }

    componentWillMount() {


        //   let cookie = new Cookies();
        // let userMobileNo = cookie.get('user-mobileNo');
        // let token = cookie.get('token');
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                console.log('mobile');
                return false;

            }
        }



        // console.log('storageClear');

        // var _launchDateTime = sessionStorage.setItem("lnch-d-t", );
        // var _nowDT = new Date();


        localStorage.clear();
        sessionStorage.setItem('u-l-v-url-dt', new Date().toString()); // Current date time Update 
        sessionStorage.setItem('app-ver', Config.appVersion);

        urlPattern = sessionStorage.getItem('url-pattern');

        // inputParam = QueryString.parse(window.location.search);
        // if (inputParam.ukey === undefined || inputParam.ukey === "" || inputParam.ukey === null) {
        //     this.props.history.push('/qrcodeerror');
        //     return false;
        // }

        // let urlkey = inputParam.ukey
        //  console.log(inputParam.ukey);

        let urlkey = sessionStorage.getItem('url-key');

        if (!urlkey || !urlPattern) {
            // Handle error or redirect as needed
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }




        // longUrlInput
        const urlString = urlkey.replace(/ /g, '+')

        let posturlObj = {
            username: Config.linkUserName,
            password: Config.linkPassword,
            urlKey: urlString,
            requestIP: "",
            userId: ""
        }

        // Update Contact Detail
        fetch(Config.baseAPIUrl + 'LongUrl', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(posturlObj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.isSuccessful) {
                    // console.log('success => redirection');
                    if (urlString === response.urlKey) {
                        this.getLaunch(response);
                    }
                    else {
                        this.showMessage("URL not found. ", "danger");
                        this.props.history.push('/qrcodeerror' + urlPattern);
                    }
                    //this.getLaunch(response);
                }
                else {
                    // console.log(response.message);
                    this.showMessage(response.message, "danger");
                    // jk              
                    setTimeout(() => {
                        this.props.history.push('/qrcodeerror' + urlPattern);
                    }, 500);
                }
            })
            .catch(err => {
                //console.log(err);
                this.showMessage("URL not found. ", "danger");
            });
    }

    componentDidMount() {
        setTimeout(() => {
            //  console.log("State variable will be set to true after 5 seconds");
            this.setState({ showComponent: true }); // Set state variable to true after 3 seconds
        }, 1000);
    }


    getCMSDetails() {

        let postobj = {
            organizationId: parseInt(orgId),
            propertyId: propId,
            outletCode: outCode,
            ServiceMode: sType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.splash, // Default Menu
            PageType: Config.pageType.menu, // spalashScreen           
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        }
        //console.log(postobj);

        fetch(Config.baseAPIUrl + 'PageDetails', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                if (response.isSuccessful) {
                    // console.log(response);
                    sessionStorage.setItem("pageCMSSettings", JSON.stringify(response));
                    this.themeStyle(response);
                }

                setTimeout(() => {
                    this.getOutletDetails();
                }, 1000);


                this.setState({ loading: false });

            })
            .catch(err => {
                console.log(err);
                setTimeout(() => {
                    this.getOutletDetails();
                }, 1000);
                //this.showMessage("CMS - Failed to fetch", "danger");
            });

        // Update Cart Infomation
    }

    themeStyle(response) {
        //console.log(response.brands);     

        // Conte Style
        let powbgColor = response.brands.filter(b => b.brandCode === 'pow-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 'pow-b-c')[0].brandValue : "#fff";
        let powfontColor = response.brands.filter(b => b.brandCode === 'pow-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'pow-f-c')[0].brandValue : "#000";
        let powfontSize = response.brands.filter(b => b.brandCode === 'pow-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'pow-f-s')[0].brandValue : "10px";

        let styleContent = {
            backgroundColor: powbgColor,
            color: powfontColor,
            fontSize: powfontSize
        }
        // console.log(styleContent);
        this.setState({ poweredByStyles: styleContent });

        //powered by lucid
        let powLucidfontColor = response.brands.filter(b => b.brandCode === 'pow-lucid-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'pow-lucid-f-c')[0].brandValue : "#000";

        styleContent = {
            color: powLucidfontColor
        }
        this.setState({ poweredByLucidStyles: styleContent });

        // Content                   
        if (response.page != null) {
            //      console.log(response.page.preview);
            this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });
        }

        // Page bg Style

        let pagebgColor = response.brands.filter(b => b.brandCode === 'pg-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 'pg-b-c')[0].brandValue : "#fff";
        styleContent = {
            background: pagebgColor,
        }
        this.setState({ pagebgColor: styleContent });
    }

    getAutoLogin() {
        this.handleUpdateContactDetail(appContactNo);
        sessionStorage.setItem('user-mobileno', sessionStorage.getItem('app-ContactNo'));
        sessionStorage.setItem('main-guest-no', sessionStorage.getItem('app-ContactNo'));
        sessionStorage.setItem('user-loggedin', true);
        sessionStorage.setItem('user-table-verified', true);
    }

    getOutletDetails() {
        // // Get Token
        let urlParam = '?OrganizationId=' + orgId + '&PropertyId=' + propId + '&TokenKey=' + Config.tokenKey;
        // console.log(urlParam);
        fetch(Config.basePOSUrl + 'GetGuestAppToken' + urlParam, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                // let cookie = new Cookies();

                if (response.errorCode === 0) {
                    token = response.response.token;
                    sessionStorage.setItem('user-token', token);

                    if (outCode === "NONE") { // Property Level
                        sessionStorage.setItem('user-tableno', "NONE");
                        sessionStorage.setItem('user-table-verified', false);
                        sessionStorage.setItem('user-qr-level', Config.qrCodeLevel.Property); // it is Property Level
                        sessionStorage.setItem('user-mobileno', "0000000000");
                        sessionStorage.setItem('main-guest-no', "0000000000");
                        sessionStorage.setItem('user-loggedin', false);
                        sessionStorage.setItem('user-table-verified', false);
                        sessionStorage.setItem('user-digitalMenu', false);
                        sessionStorage.setItem('group-session', false);

                        // Check Mobile App mode
                        if (mobileApp === true) {
                            // console.log(sessionStorage.getItem('mobile-app'));
                            //this.handleUpdateContactDetail(appContactNo);
                            this.getAutoLogin();
                            setTimeout(() => {
                                //  this.props.history.push('/home');
                                this.props.history.push('/home' + urlPattern, { guestAppLandingPage: outletDetails.guestAppLandingPage });
                            }, 1000);
                            return false;
                        }
                        else {
                            setTimeout(() => {
                                //   this.props.history.push('/home');
                                this.props.history.push('/home' + urlPattern, { guestAppLandingPage: outletDetails.guestAppLandingPage });
                            }, 1000);
                            return false;
                        }

                    }
                    else if (sType === "NONE") { // Outlet Level

                        sessionStorage.setItem('user-tableno', "NONE");
                        sessionStorage.setItem('user-table-verified', false);
                        sessionStorage.setItem('user-qr-level', Config.qrCodeLevel.Outlet); // it is Property Level
                        sessionStorage.setItem('user-mobileno', "0000000000");
                        sessionStorage.setItem('main-guest-no', "0000000000");
                        sessionStorage.setItem('user-loggedin', false);
                        sessionStorage.setItem('user-table-verified', false);
                        sessionStorage.setItem('user-digitalMenu', false);
                        sessionStorage.setItem('group-session', false);

                        if (mobileApp === true) {
                            this.handleUpdateContactDetail(appContactNo);
                        }

                        setTimeout(() => {
                            //   this.props.history.push('/home');
                            this.props.history.push('/home' + urlPattern, { guestAppLandingPage: outletDetails.guestAppLandingPage });
                        }, 1000);
                        return false;

                    }

                    // get Outlet Details
                    let uParam = '?OutletId=' + propId + outCode + '&ServiceType=' + sType;

                    fetch(Config.basePOSUrl + 'GetOutletDetails' + uParam, {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer " + token,
                            'content-type': 'application/json'
                        }
                    })
                        .then(res => res.json())
                        .then(response => {
                            // console.log(response);
                            if (response.errorCode === 0) {

                                outletDetails = response.response.outletDetails;
                                // console.log(outletDetails);
                                sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                                sessionStorage.setItem('user-propertyname', outletDetails.propertyName);
                                sessionStorage.setItem('user-outletname', outletDetails.outletName);

                                setTimeout(() => {
                                    //  console.log("State variable will be set to true after 5 seconds");
                                    this.setState({ showComponent: true }); // Set state variable to true after 3 seconds
                                }, 1000);

                                
                                if (outletDetails.enableGuestAppOrders === false && outletDetails.enableDigitalMenu === true) {
                                    // Required Login Details
                                    if (outletDetails.guestDetailsMandatoryForDigitalMenu === false) {
                                        sessionStorage.setItem('user-token', token);
                                        sessionStorage.setItem('user-table-verified', true);
                                        sessionStorage.setItem('user-digitalMenu', true);
                                        sessionStorage.setItem('user-mobileno', "0000000000");

                                        if (mobileApp === true) this.getAutoLogin();
                                        setTimeout(() => {
                                            //this.props.history.push('/emhome');
                                            //   this.props.history.push('/home');
                                            this.props.history.push('/home' + urlPattern, { guestAppLandingPage: outletDetails.guestAppLandingPage });
                                        }, 2000);
                                    }
                                    else {
                                        // Existing logged mode                                      
                                        if (exloggedout === true) {
                                            setTimeout(() => {
                                                this.props.history.push('/login' + urlPattern);
                                                this.setState({ loading: false });
                                            }, 1500);
                                            return false;
                                        }

                                        if (mobileNo === "0000000000") {
                                            setTimeout(() => {
                                                this.props.history.push('/login' + urlPattern);
                                                this.setState({ loading: false });
                                            }, 1500);
                                            return false;
                                        }

                                        if (mobileNo === "" || mobileNo === null || mobileNo === undefined) {
                                            setTimeout(() => {
                                                this.props.history.push('/login' + urlPattern);
                                                this.setState({ loading: false });
                                            }, 1500);
                                            return false;
                                        }
                                        else {
                                            if (token === undefined || token === "" || token === null) {
                                                setTimeout(() => {
                                                    this.props.history.push('/login' + urlPattern);
                                                    this.setState({ loading: false });
                                                }, 2500);
                                                return false;
                                            }
                                            else {
                                                sessionStorage.setItem('user-token', token);
                                                sessionStorage.setItem('user-table-verified', true);
                                                sessionStorage.setItem('user-digitalMenu', true);

                                                if (mobileApp === true) this.getAutoLogin();

                                                setTimeout(() => {
                                                    this.setState({ loading: false });
                                                    //this.props.history.push('/emhome');
                                                    //  this.props.history.push('/home');
                                                    this.props.history.push('/home' + urlPattern, { guestAppLandingPage: outletDetails.guestAppLandingPage });
                                                }, 2000);
                                                return false;
                                            }
                                        }
                                    }

                                } // End Digital Menu
                                else if (outletDetails.enableGuestAppOrders === true) {
                                    sessionStorage.setItem('user-digitalMenu', false);
                                    sessionStorage.setItem('group-session', false);
                                    sessionStorage.setItem('user-token', token);
                                    //console.log(outletDetails);
                                    /// Guest Login Mode
                                    if (outletDetails.guestLoginAtTheTimeOfPlaceOrder) {
                                        sessionStorage.setItem('user-mobileno', "0000000000");
                                        sessionStorage.setItem('main-guest-no', "0000000000");
                                        sessionStorage.setItem('user-loggedin', false);
                                        sessionStorage.setItem('user-table-verified', true);
                                        sessionStorage.removeItem('guest-name');
                                        if (mobileApp === true) this.getAutoLogin();

                                        setTimeout(() => {
                                            this.setState({ loading: false });
                                            this.props.history.push('/home' + urlPattern, { guestAppLandingPage: outletDetails.guestAppLandingPage });
                                        }, 2000);
                                        return false;
                                    }
                                    else {
                                        if (mobileApp === true) {
                                            this.getAutoLogin();
                                            setTimeout(() => {
                                                this.setState({ loading: false });
                                                // this.props.history.push('/home');
                                                this.props.history.push('/home' + urlPattern, { guestAppLandingPage: outletDetails.guestAppLandingPage });
                                            }, 2000);
                                            return false;
                                        }
                                    }

                                    if (sType !== "FINE-DINE") {
                                        // Existing logged mode
                                        if (exloggedout === true) {
                                            setTimeout(() => {
                                                this.props.history.push('/login' + urlPattern);
                                                this.setState({ loading: false });
                                            }, 2500);
                                            return false;
                                        }

                                        if (mobileNo === "0000000000") {
                                            setTimeout(() => {
                                                this.props.history.push('/login' + urlPattern);
                                                this.setState({ loading: false });
                                            }, 2500);
                                            return false;
                                        }

                                        if (mobileNo === "" || mobileNo === null || mobileNo === undefined) {
                                            setTimeout(() => {
                                                this.props.history.push('/login' + urlPattern);
                                                this.setState({ loading: false });
                                            }, 2500);
                                            return false;
                                        }
                                        else {

                                            if (token === undefined || token === "" || token === null) {
                                                setTimeout(() => {
                                                    this.props.history.push('/login' + urlPattern);
                                                    this.setState({ loading: false });
                                                }, 2500);
                                                return false;
                                            }
                                            else {
                                                setTimeout(() => {
                                                    this.setState({ loading: false });
                                                    this.props.history.push('/home' + urlPattern);
                                                }, 2000);
                                            }
                                        }
                                        return false;
                                    } // End Not Fine Dine

                                    // Group Order - on 12-10-2020
                                    let postObjGuest = {
                                        OutletId: propId + outCode,
                                        TableNumber: tblNo
                                    };

                                    //  console.log('jk');

                                    fetch(Config.basePOSUrl + 'GetGuestTableStatus' + uParam, {
                                        method: 'POST',
                                        headers: {
                                            Authorization: "Bearer " + token,
                                            'content-type': 'application/json'
                                        },
                                        body: JSON.stringify(postObjGuest)
                                    })
                                        .then(res => res.json())
                                        .then(response => {
                                            if (response.errorCode === 0) {
                                                if (!response.response.guestTableStatus.isCheckedIn) {
                                                    // Existing logged mode
                                                    if (exloggedout === true) {
                                                        setTimeout(() => {
                                                            this.props.history.push('/login' + urlPattern);
                                                            this.setState({ loading: false });
                                                        }, 2500);
                                                        return false;
                                                    }
                                                    if (mobileNo === "0000000000") {
                                                        setTimeout(() => {
                                                            this.props.history.push('/login' + urlPattern);
                                                            this.setState({ loading: false });
                                                        }, 2500);
                                                        return false;
                                                    }

                                                    if (mobileNo === "" || mobileNo === null || mobileNo === undefined) {
                                                        setTimeout(() => {
                                                            this.props.history.push('/login' + urlPattern);
                                                            this.setState({ loading: false });
                                                        }, 2500);
                                                        return false;
                                                    }
                                                    else {

                                                        if (token === undefined || token === "" || token === null) {
                                                            setTimeout(() => {
                                                                this.props.history.push('/login' + urlPattern);
                                                                this.setState({ loading: false });
                                                            }, 2500);
                                                            return false;
                                                        }
                                                        else {
                                                            setTimeout(() => {
                                                                this.setState({ loading: false });
                                                                this.props.history.push('/login' + urlPattern); // /home
                                                            }, 2000);
                                                        }
                                                    }

                                                } // End checked in false
                                                else {
                                                    sessionStorage.setItem('user-token', token);
                                                    sessionStorage.setItem('main-guest-mask-no', response.response.guestTableStatus.maskMobileNo);
                                                    sessionStorage.setItem('main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                                                    sessionStorage.setItem('ex-main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);

                                                    this.setState({ loading: true });

                                                    // setTimeout(() => {
                                                    //     this.setState({ loading: false });
                                                    //     this.props.history.push('/jointable');
                                                    // }, 2000);

                                                    setTimeout(() => {
                                                        this.props.history.push('/login' + urlPattern);
                                                        this.setState({ loading: false });
                                                    }, 2500);

                                                    return false;
                                                }

                                            }
                                            else {

                                                sessionStorage.removeItem("user-token");
                                                sessionStorage.removeItem("user-mobileno");
                                                // console.log(response.message);
                                                this.showMessage(response.message, "danger");

                                            }
                                        })
                                        .catch(err => {
                                            sessionStorage.removeItem("user-token");
                                            sessionStorage.removeItem("user-mobileno");
                                            this.showMessage('Table Status - Failed to fetch - ' + err, "danger");
                                            console.log('Table Status - Failed to fetch - ' + err);
                                        });

                                } // End Contion CL/Digital
                                else { // App not available
                                    setTimeout(() => {
                                        this.props.history.push('/error404' + urlPattern);
                                        this.setState({ loading: false });
                                    }, 2500);
                                }

                                // setTimeout(() => {
                                //     this.props.history.push('/emhome');
                                // }, 2000);

                            }
                            else {
                                sessionStorage.removeItem("user-token");
                                sessionStorage.removeItem("user-mobileno");
                                this.showMessage(response.message, "danger");
                                console.log(response.message);
                            }
                        })
                        .catch(err => {
                            sessionStorage.removeItem("user-token");
                            sessionStorage.removeItem("user-mobileno");
                            this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
                            console.log('Outlet Details - Failed to fetch - ' + err);
                        });
                }
                else {
                    // cookie.remove('token');
                    // cookie.remove('user-mobileNo');
                    sessionStorage.removeItem("user-token");
                    sessionStorage.removeItem("user-mobileno");
                    this.showMessage(response.message, "danger");
                    console.log(response.message);
                }
            })
            .catch(err => {
                console.log(err);
                this.showMessage("An error occured", "danger");
            });
        // let bDigital = this.getDigitalMenu(orgId, propId, outCode, sType);


    }

    handleUpdateContactDetail(contactNo) {
        let posturlObj = {
            organizationId: parseInt(orgId),
            propertyId: propId,
            outletCode: outCode,
            ServiceMode: sType,
            ContactNo: contactNo,
            StatsId: parseInt(statsid),
            numofMenuAccess: 1,
            username: Config.linkUserName,
            password: Config.linkPassword
        }
        // Update Contact Detail
        fetch(Config.baseAPIUrl + 'ClickURLDetail', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(posturlObj)
        })
            .then(res => res.json())
            .then(response => {
                //console.log(response);
                this.redirectToHome();

            })
            .catch(err => {
                console.log(err);
                this.redirectToHome();
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }

    redirectToHome() {
        sessionStorage.setItem('user-table-verified', true);
        sessionStorage.removeItem('otpReferenceId');
        sessionStorage.setItem('user-loggedin', true);

        sessionStorage.setItem('user-mobileno', appContactNo);
        sessionStorage.setItem('main-guest-no', appContactNo);
        this.getGuestDetails();
    }

    getGuestDetails() {

        let mobileNumber = sessionStorage.getItem('user-mobileno');
        fetch(Config.baseGuestUrl + 'GetGuestDetails?GuestMobileNo=' + mobileNumber, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                //console.log(response);
                if (response.errorCode === 0) {
                    sessionStorage.setItem('user-GuestDetails', JSON.stringify(response.response.guestDetails));
                    let selectedAddress = {
                        addressId: response.response.guestDetails.guestAddress.addressId,
                        addressLine1: response.response.guestDetails.guestAddress.addressLine1,
                        addressLine2: response.response.guestDetails.guestAddress.addressLine2,
                        addressLine3: response.response.guestDetails.guestAddress.addressLine3,
                        addressType: response.response.guestDetails.guestAddress.addressType,
                        addressTypeId: 1,//response.response.guestDetails.addressTypeId,
                        area: response.response.guestDetails.guestAddress.area,
                        city: response.response.guestDetails.guestAddress.city,
                        landmark: response.response.guestDetails.guestAddress.landmark,
                        zipCode: response.response.guestDetails.guestAddress.zipCode
                    }
                    sessionStorage.setItem('user-CurrentAddress', JSON.stringify(selectedAddress));
                }
                else {
                    this.showMessage(response.message, "danger");
                    sessionStorage.removeItem('user-GuestDetails');
                }

                // this.setState({ loading: true });
                // Redirect to page              

                // setTimeout(() => {
                //     this.setState({ loading: false });
                //     this.props.history.push('/home');
                // }, 2000);
                // return false;

            })
            .catch(err => {
                this.showMessage('Guest Details  - Failed to fetch - ' + err, "danger");
            });
    }

    handleCheckJoinTable() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='join-table-alert'>
                        <img className="img-center table-img" src="../assets/theme1/img/table-logo-w.png" alt="lucid-contactless-app-user-table-service" />
                        <h1>Join Table</h1>
                        <h6>Welcome to {sessionStorage.getItem("user-outletname")}</h6>
                        <p className="notify-msg">Table No 103 is occupied. Do you want to join the table?</p>
                        <button onClick={onClose}>No</button>
                        <button
                            onClick={() => {
                                // this.handleClickDelete();
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                    </div>
                );
            }
        });
    }

    getDigitalMenu(orgId, propId, outCode, sType) {
        let bDigital = false;
        // // Get Token
        let urlParam = '?OrganizationId=' + orgId + '&PropertyId=' + propId + '&TokenKey=' + Config.tokenKey;
        // console.log(urlParam);
        fetch(Config.basePOSUrl + 'GetGuestAppToken' + urlParam, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                // let cookie = new Cookies();
                if (response.errorCode === 0) {
                    token = response.response.token;

                    // get Outlet Details
                    let uParam = '?OutletId=' + propId + outCode + '&ServiceType=' + sType;

                    fetch(Config.basePOSUrl + 'GetOutletDetails' + uParam, {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer " + token,
                            'content-type': 'application/json'
                        }
                    })
                        .then(res => res.json())
                        .then(response => {
                            //console.log(response);
                            if (response.errorCode === 0) {

                                outletDetails = response.response.outletDetails;

                                if (outletDetails.enableGuestAppOrders === false && outletDetails.enableDigitalMenu === true) {
                                    sessionStorage.setItem('user-token', token);
                                    sessionStorage.setItem('user-table-verified', false);
                                    sessionStorage.setItem('user-digitalMenu', true);
                                    sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                                    sessionStorage.setItem('user-mobileno', "0000000000");
                                    bDigital = true;
                                    return bDigital;

                                }
                                else {
                                    sessionStorage.setItem('user-digitalMenu', false);
                                }

                                // setTimeout(() => {
                                //     this.props.history.push('/emhome');
                                // }, 2000);

                            }
                            else {
                                sessionStorage.removeItem("user-token");
                                sessionStorage.removeItem("user-mobileno");
                                this.showMessage(response.message, "danger");
                            }
                        })
                        .catch(err => {
                            sessionStorage.removeItem("user-token");
                            sessionStorage.removeItem("user-mobileno");
                            this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
                        });
                }
                else {
                    // cookie.remove('token');
                    // cookie.remove('user-mobileNo');
                    sessionStorage.removeItem("user-token");
                    sessionStorage.removeItem("user-mobileno");
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                console.log(err);
                this.showMessage("An error occured", "danger");
            });

        return bDigital;

    }

    getUpdateOutletDetails() {
        // get Outlet Details
        let uParam = '?OutletId=' + propId + outCode + '&ServiceType=' + sType;

        fetch(Config.basePOSUrl + 'GetOutletDetails' + uParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                if (response.errorCode === 0) {

                    outletDetails = response.response.outletDetails;
                    // console.log(outletDetails);
                    sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                    sessionStorage.setItem('user-propertyname', outletDetails.propertyName);
                    sessionStorage.setItem('user-outletname', outletDetails.outletName);
                }
            })
            .catch(err => {
                sessionStorage.removeItem("user-token");
                sessionStorage.removeItem("user-mobileno");
                this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
                console.log('Outlet Details - Failed to fetch - ' + err);
            });
    }

    //Added by vijay on 27-10-2023
    getLaunch(response) {


        const longUrlInput = response.longUrlInput;

        orgId = longUrlInput.organizationId;
        orgName = longUrlInput.organizationName;
        propId = longUrlInput.propertyId;
        propName = longUrlInput.propertyName;
        outCode = longUrlInput.outletCode;
        outName = longUrlInput.outletName;
        tblNo = longUrlInput.seriviceNo;
        sType = longUrlInput.serviceType;
        statsid = longUrlInput.statsid;
        urlRemarks = longUrlInput.remarks;
        mobileApp = longUrlInput.mobileApp;
        appContactNo = longUrlInput.contactNo;
        appCountryCode = longUrlInput.contactCountryCode;
        // orgId = inputParam.orgid;
        // orgName = inputParam.orgname;
        // propId = inputParam.propid;
        // propName = inputParam.propname;
        // outCode = inputParam.outtcode;
        // outName = inputParam.outtname;
        // tblNo = inputParam.tblno;
        // sType = inputParam.stype;
        // statsid = inputParam.statsid;
        // urlRemarks = inputParam.remarks;
        // mobileApp = inputParam.app;
        // appContactNo = inputParam.mno;
        // appCountryCode = inputParam.mccode;
        //console.log(inputParam);
        // console.log(orgId);
        // console.log(propId);
        // console.log(outCode);
        // console.log(tblNo);
        //console.log(mobileApp);

        if (orgId === undefined || orgId === "" || orgId === null) {
            // if (loggedInOrg === undefined || loggedInOrg === "" || loggedInOrg === null) {
            //     orgName = '';
            //     this.props.history.push('/qrcodeerror');
            //     return false;
            // }
            // else {
            //     orgId = loggedInOrg;
            //     orgName = loggedOrgName;
            // }

            this.props.history.push('/qrcodeerror' + urlPattern);
            return false;
        }
        if (propId === undefined || propId === "" || propId === null) {
            // if (loggedInPropertyId === undefined || loggedInPropertyId === "" || loggedInPropertyId === null) {
            //     propName = '';
            //     this.props.history.push('/qrcodeerror');
            //     return false;
            // }
            // else {
            //     propId = loggedInPropertyId;
            //     propName = loggedPropertyName;
            // }
            this.props.history.push('/qrcodeerror' + urlPattern);
            return false;
        }
        if (outCode === undefined || outCode === "" || outCode === null) {
            // if (loggedInOutletcode === undefined || loggedInOutletcode === "" || loggedInOutletcode === null) {
            //     this.props.history.push('/qrcodeerror');
            //     return false;
            // }
            // else {
            //     outCode = loggedInOutletcode;
            //     outName = loggedOutletName;
            // }
            this.props.history.push('/qrcodeerror' + urlPattern);
            return false;
        }

        if (tblNo === undefined || tblNo === "" || tblNo === null) {
            // if (loggedInTableno === undefined || loggedInTableno === "" || loggedInTableno === null) {
            //     this.props.history.push('/qrcodeerror');
            //     return false;
            // }
            // else {
            //     tblNo = loggedInTableno;
            // }
            this.props.history.push('/qrcodeerror' + urlPattern);
            return false;
        }
        if (sType === undefined || sType === "" || sType === null) {
            // if (loggedInServiceType === undefined || loggedInServiceType === "" || loggedInServiceType === null) {
            //     this.props.history.push('/qrcodeerror');
            //     return false;
            // }
            // else {
            //     sType = loggedInServiceType;
            // }
            this.props.history.push('/qrcodeerror' + urlPattern);
            return false;
        }

        if (statsid === undefined || statsid === "" || statsid === null) {
            // if (loggedInPropertyId === undefined || loggedInPropertyId === "" || loggedInPropertyId === null) {
            //     statsid = "0";
            // }
            // else {
            //     statsid = loggedStatsid;
            // }
            statsid = 0;
        }

        if (urlRemarks === undefined || urlRemarks === "" || urlRemarks === null) {
            // if (loggedurlRemarks === undefined || loggedurlRemarks === "" || loggedurlRemarks === null) {
            //     urlRemarks = "";
            // }
            // else {
            //     urlRemarks = loggedurlRemarks;
            // }
            urlRemarks = '';
        }




        if (mobileApp === undefined || mobileApp === "" || mobileApp === null) {
            mobileApp = false;
        }
        else {
            mobileApp = mobileApp === "true" ? true : false;
        }


        // check the existing log history


        // if (orgId !== loggedInOrg) {
        //     exloggedout = true;
        // }

        // if (propId !== loggedInPropertyId) {
        //     exloggedout = true;
        // }

        // if (outCode !== loggedInOutletcode) {
        //     exloggedout = true;
        // }

        // if (tblNo !== loggedInTableno) {
        //     exloggedout = true;
        // }

        // if (sType !== loggedInServiceType) {
        //     exloggedout = true;
        // }



        sessionStorage.setItem('user-orgnationid', orgId);
        sessionStorage.setItem('user-propertyid', propId);
        sessionStorage.setItem('user-outletcode', outCode);
        sessionStorage.setItem('user-tableno', tblNo);
        sessionStorage.setItem('user-servicetype', sType);
        sessionStorage.setItem('user-organizationname', orgName);
        sessionStorage.setItem('user-propertyname', propName);
        sessionStorage.setItem('user-outletname', outName);
        sessionStorage.setItem('user-statsid', statsid);
        sessionStorage.setItem('url-remarks', urlRemarks === null ? '' : urlRemarks === undefined ? '' : urlRemarks);
        sessionStorage.setItem('mobile-app', mobileApp === null ? false : mobileApp === undefined ? false : mobileApp);
        sessionStorage.setItem('app-ContactNo', appContactNo === null ? '0000000000' : appContactNo === undefined ? '0000000000' : appContactNo);
        sessionStorage.setItem('app-CountryCode', appCountryCode === null ? '91' : appCountryCode === undefined ? '91' : appCountryCode);
        sessionStorage.setItem('user-session-id', uuid());
        // this.setState({orgId: orgId});
        // this.setState({propertyId: propId});
        // this.setState({outletCode: outCode});
        // this.setState({tableNo: tblNo});
        // this.setState({serviceType: sType});

        // digital Menu

        // group pin added by dasprakash on 06-10-2023 jira-1490
        sessionStorage.removeItem('table-pins')
        sessionStorage.removeItem('user-digitalMenu');
        sessionStorage.removeItem("user-outletDetails");
        sessionStorage.removeItem('main-guest-mask-no');
        sessionStorage.removeItem('main-guest-no');
        sessionStorage.removeItem('ex-main-guest-no');
        sessionStorage.removeItem('login-page-session');
        sessionStorage.removeItem('user-loggedin');
        if (mobileNo === "" || mobileNo === null || mobileNo === undefined || mobileNo === "0000000000") {
            sessionStorage.removeItem('guest-name');
        }

        sessionStorage.removeItem("cartList");
        sessionStorage.removeItem("taxCartList");
        sessionStorage.removeItem("modifierCartList");
        sessionStorage.removeItem("chargeCartList");
        sessionStorage.removeItem("paymentInitiatedId");
        sessionStorage.removeItem("paymentStatus");
        sessionStorage.removeItem("vendorId");
        sessionStorage.removeItem("vendorName");
        sessionStorage.removeItem("order-instruction");

        sessionStorage.removeItem("getCharges");
        sessionStorage.removeItem("getTaxes");
        sessionStorage.removeItem("getMenuGroups");
        sessionStorage.removeItem("getModifierGroups");
        sessionStorage.removeItem("getmenuItems");
        sessionStorage.removeItem('otpReferenceId');
        sessionStorage.removeItem('user-outletDetails');
        sessionStorage.removeItem('user-table-verified');
        sessionStorage.removeItem("ebillUrl");
        sessionStorage.removeItem("isPay");
        sessionStorage.removeItem("posBillId");
        sessionStorage.removeItem("on-click-mode");
        sessionStorage.removeItem('user-qr-level');
        sessionStorage.removeItem('user-GuestDetails');
        sessionStorage.removeItem('order-track-status');
        sessionStorage.removeItem('pageCMSSettings');
        sessionStorage.removeItem('menuFetchDateTime');

        sessionStorage.removeItem('group-pin');
        sessionStorage.removeItem('dashboard-hide');
        sessionStorage.removeItem('dashboard-hide');
        ///added by dasprakash on 20-10-2023 jira-1645
        sessionStorage.removeItem('entered-mobile-no');
        sessionStorage.removeItem('entered-name');
        ///added by dasprakash on 07-11-2023 jira-1744
        sessionStorage.removeItem('user-access-mode');
        sessionStorage.removeItem('otp');
        sessionStorage.removeItem('verified-otp');
        sessionStorage.removeItem('selectedClassification');
        sessionStorage.removeItem('dashboard-hide');
        sessionStorage.removeItem('order-charge-Tax-Amount');
        sessionStorage.removeItem('getclf');
        sessionStorage.removeItem('order-charge-Amount');
        sessionStorage.removeItem('app-ContactNo');
        sessionStorage.removeItem('order-charge-List');

        // sessionStorage.removeItem('user-addressId');
        // sessionStorage.removeItem('user-CurrentAddress');
        // Property Level


        // get CMSDetails

        this.getCMSDetails();


        // this.setState({ loading: true });
        // setTimeout(() => {
        //     this.props.history.push('/login', {
        //         orgId: this.state.orgId,
        //         propertyId: this.state.propertyId,
        //         outletCode: this.state.outletCode,
        //         tableNo: this.state.tableNo,
        //         serviceType: this.state.serviceType,
        //     });
        //     this.setState({ loading: false });
        // }, 2500);
    }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, 2000);
    };

    render() {
        return (
            <div>
                {/* {loading && <LoadingAnimation />}             */}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                <div id="login-content">
                    <div className="content-wrap screen_bg" style={this.state.pagebgColor} >
                        <div>
                            {/* {
                                this.state.pagePreview !== "" &&
                                <div className="screen-wel-msg lucid-lineUp" dangerouslySetInnerHTML={{ __html: this.state.pagePreview }}>

                                </div>
                            } */}

                            {this.state.showComponent && (
                                // Render the component only if showComponent is true
                                this.state.pagePreview !== "" ?
                                    <div className="screen-wel-msg lucid-lineUp" dangerouslySetInnerHTML={{ __html: this.state.pagePreview }}>
                                        {/* {console.log("Page Preview is not empty:", this.state.pagePreview)} */}
                                    </div>
                                    :
                                    // (outCode !== "NONE" && sessionStorage.getItem('user-outletDetails') !== null && sessionStorage.getItem('user-outletDetails').outletImageUrl ?
                                    (outCode !== "NONE" && outletDetails !== null && outletDetails.outletImageUrl ?
                                        <div className="screen-wel-msg lucid-lineUp">
                                            {/* {console.log("Outlet Image URL:", outletDetails.outletImageUrl)} */}
                                            <img src={outletDetails.outletImageUrl} alt="" style={this.state.logoheight} />
                                        </div>
                                        :
                                        // Replace null with rendering of outletDetails.propertyImageUrl if outletDetails.outletImageUrl is false
                                        outletDetails.propertyImageUrl ? (
                                            <div className="screen-wel-msg lucid-lineUp">
                                                <img src={outletDetails.propertyImageUrl} alt=""  style={this.state.logoheight} />
                                            </div>
                                        ) : null
                                    )
                            )}

                            {/* <img src={LoadingImg} className="svg" alt="" />  */}
                            {/* <img src={HomeImg} className="screen-img-center" alt="lucid-contactless-food-ordering" /> */}
                            <div className='screen-lucid-poweredby' style={this.state.poweredByStyles}>
                                {/* <img src={lucidloader} alt="lucid-contactless-food-ordering" /> */}
                                <div>Powered By <span style={this.state.poweredByLucidStyles}>LUCID</span>
                                    {/* <div class="screen-waviy" style={this.state.poweredByLucidStyles}>
                                        <span >L</span>
                                        <span >U</span>
                                        <span >C</span>
                                        <span >I</span>
                                        <span>D</span>
                                      

                                    </div> */}

                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <img src={LoadingImg} className="svg screen-img-center loading_img" alt="" /> */}
                </div>
            </div>
        )
    }

}
export default SplashScreen;